import { userGuidePublic } from "../../../constants/links";

export const navLinks = [
  {
    title: "Home",
  },
  {
    title: "e-Permits",
    type: "dropdown",
    children: [
      {
        label: "Development Permit",
        route: "/permit/development-permit",
      },
      {
        label: "Outdoor Advertisement Permit",
        route: "/permit/outdoor-advertisement-permit",
      },
      {
        label: "Subdivision/Consolidation Permit",
        route: "/permit/subdivision-consolidation-permits",
      },
      {
        label: "Lease Application",
        route: "/permit/lease-application",
      },
      {
        label: "Condominium Permit",
        route: "/permit/condominium-permit",
      },
      {
        label: "Chainlink Permit",
        route: "/permit/chainlink-permit",
      },
    ],
  },
  {
    title: "Digital Services",
    type: "dropdown",
    children: [
      {
        label: "Online Payments",
        link: "/user/register",
      },
      {
        label: "Check PRN Status",
        link: "prn",
      },
      {
        label: "Check Application Status",
        link: "application-status",
      },
      {
        label: "Register/Track Inquiry",
        to: "#",
      },
      {
        label: "Geo Portal",
        to: "#",
      },
    ],
  },
  {
    title: "Publications",
    type: "dropdown",
    children: [
      {
        label: "Regulations & Acts",
        type: "stringList",

        children: [
          {
            label: "Physical Planning Act 2010",
            link: "https://iras.go.ug/media/documents/physical-planning-fees/Physical-Planning-Act-2010.pdf",
          },
          {
            label: "National Physical Planning Standards & Guidelines 2011",
            link: "https://iras.go.ug/media/documents/physical-planning-fees/National-Physical-Planning-Standards-and-Guidelines_-2011.pdf",
          },
          {
            label: "The Buildings Control Regulations",
            link: "https://iras.go.ug/media/documents/physical-planning-fees/The%20Buildings%20Control%20Regulations%202020.pdf",
          },
          {
            label: "Building Fees Control Regulations 2020",
            link: "https://iras.go.ug/media/documents/Building-Fees-Control-Regulations-2020-1.pdf",
          },
        ],
      },
    ],
  },
  {
    title: "User Guide",
    link: userGuidePublic,
    blank: false,
  },
];

export const externalLinks = [
  {
    title: "Kampla Capital City Authority",
    to: "https://www.kcca.go.ug/",
  },
  {
    title: "KCCA Football Club",
    to: "https://kccafc.co.ug",
  },
  {
    title: "Uganda Revenue Authority",
    to: "https://www.ura.go.ug",
  },
  {
    title: "Local Goverment Finance Commision",
    to: "https://lgfc.go.ug/",
  },
];
